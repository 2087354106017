import React from 'react';
import { Popup } from 'semantic-ui-react';

import Form from './Form';
import Calendar from '../Calendar';

import * as utils from '../../utils';

export default class CalendarDropdown extends React.Component {
  render() {
    const {
      label, error, lang, value, setValue, labelStyle, inputStyle, style, minDate, maxDate
    } = this.props;

    return (
      <Popup
        trigger={(
          <div style={{ width: '100%', ...style }}>
            <Form.Input
              className="calendar-input"
              label={label}
              labelStyle={labelStyle}
              style={inputStyle}
              readOnly
              icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
              error={error}
              value={utils.simpleDate(value, true, 'll', lang)}
            />
          </div>
        )}
        content={(
          <Calendar
            calendarStyles
            selectedDt={value}
            maxDate={maxDate}
            minDate={minDate}
            unselectDate={() => setValue(null)}
            onSelect={(d) => setValue(d.selectedDt)}
          />
)}
        position="bottom left"
        hideOnScroll={false}
        on="click"
      />
    );
  }
}
