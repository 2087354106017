import React from 'react';

export default class Switch extends React.Component {
  render() {
    const { left, right, onToggle, disabled, toggled, compact } = this.props;

    return (
      <label className={`toggle-switch${disabled ? ' disabled' : ''}${compact ? ' compact' : ''}`}>
        <input
          type="checkbox"
          checked={toggled}
          onChange={() => onToggle(!toggled)}
          disabled={disabled}
        />
        <div className={`switch ${toggled ? 'toggled' : ''}`}>
          <span className={toggled ? '' : 'focused'} style={{ alignSelf: '' }}>{left}</span>
          <span className={toggled ? 'focused' : ''} style={{ alignSelf: '' }}>{right}</span>
        </div>
      </label>
    );
  }
}
